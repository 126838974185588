import React from 'react';
import Layout from './layout'


import ButtonWhite from "../components/ButtonWhite"


const GalleryPage = () => {
  return (
    <Layout marginTop={true}>

    </Layout>
  );
};

export default GalleryPage;
